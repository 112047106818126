import React, {useState} from 'react'

import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'
import {useDispatch} from 'react-redux'

import {
  removeProductFromBag,
  decreaseQuantity,
  increaseQuantity
} from '../../../infra/utils/BagActions'
import Currency from '../../../infra/utils/Currency'
import getTranslationNameFromArray from '../../utils/getTranslationNameFromArray'
import GetImage from '../Image'
import ComponentLoadingOverlay from '../loading/ComponentLoadingOverlay'
import {
  BagProductContainer,
  BagProductWrapper,
  Counter,
  CounterIcon,
  CounterText,
  Price,
  PriceSpan,
  ProductCategory,
  ProductCount,
  ProductImage,
  ProductInfo,
  ProductInfoWrapper,
  ProductName,
  ProductNameLink,
  ProductSize,
  RemoveItem
} from './BagStyles'

const CartItem = ({user, product}) => {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const {languageId} = user

  const handleRemoveClick = async (bagItemId) => {
    setIsLoading(true)
    await removeProductFromBag(bagItemId, dispatch)
    setIsLoading(false)
  }

  return (
    <>
      <ComponentLoadingOverlay isLoading={isLoading}>
        <BagProductContainer key={product?.bagItemId}>
          <BagProductWrapper>
            <RemoveItem
              onClick={() => handleRemoveClick(product?.bagItemId)}
            />
            <ProductImage
              src={GetImage(
                product?.product?.productImage?.find((x) => x.cover)
              )}
            />
            <ProductInfoWrapper>
              <ProductInfo>
                <ProductCategory
                  style={{
                    color: user.configuration?.layoutColors?.contentText
                  }}
                >
                  {
                    product?.product?.mainCategory?.categoryTranslation[
                      languageId
                    ]?.name
                  }
                </ProductCategory>
                <ProductName>
                  <ProductNameLink>
                    {getTranslationNameFromArray(
                      product?.product?.productTranslation,
                      languageId
                    )}
                  </ProductNameLink>
                </ProductName>
                <ProductSize>
                  {getTranslationNameFromArray(
                    product.colorTranslation,
                    languageId
                  )}
                  {product?.productCombination.size.nameEu !== '' &&
                    ` / ${product?.productCombination.size.nameEu}`}
                </ProductSize>
                <ProductSize />
              </ProductInfo>
              <ProductCount>
                <Counter>
                  <CounterIcon
                    onClick={() =>
                      decreaseQuantity(
                        product?.bagItemId,
                        dispatch,
                        user?.languageId
                      )
                    }
                  >
                    <span>-</span>
                  </CounterIcon>

                  <CounterText>{product?.units}</CounterText>
                  <CounterIcon
                    onClick={() =>
                      increaseQuantity(
                        product?.bagItemId,
                        dispatch,
                        languageId
                      )
                    }
                  >
                    <span>+</span>
                  </CounterIcon>
                </Counter>
                <Price>
                  {product?.dotation === 0 ? (
                    <PriceSpan>
                      {Currency.format(product?.unitValue)}
                    </PriceSpan>
                  ) : (
                    <PriceSpan>
                      {product?.units}{' '}
                      {product?.units === 1 ? (
                        <Translate id='CART_CREDIT' />
                      ) : (
                        <Translate id='CART_CREDITS' />
                      )}
                    </PriceSpan>
                  )}
                </Price>
              </ProductCount>
            </ProductInfoWrapper>
          </BagProductWrapper>
        </BagProductContainer>
      </ComponentLoadingOverlay>
    </>
  )
}

CartItem.propTypes = {
  user: PropTypes.object,
  product: PropTypes.object
}

CartItem.defaultProps = {
  user: {},
  product: {}
}

export default CartItem
